<template>
  <div class="mini-footer text-center">
    <h5 class="footer-title font-meta-11-sb">PT Semesta Integrasi Digital</h5>
    <p class="footer-description font-meta-10-rg">Satu akun untuk Sekolah.mu, Karier.mu, <br />Rencanamu, dan Talentics</p>
    <p class="font-meta-10-rg">© {{ fullYear }} All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  computed: {
    fullYear () {
      const date = new Date()
      return date.getFullYear()
    }
  }
}
</script>

<style scoped>
.footer-title {
  color: var(--color-ui-text-primary);
  margin-bottom: 4px;
}

.footer-description {
  margin-bottom: 8px;
}
</style>
