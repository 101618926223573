<template>
  <div id="base-app">
    <transition name="component-fade" mode="out-in">
      <component :is="layout">
        <transition name="component-fade" mode="out-in">
          <router-view />
        </transition>
      </component>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    layout () {
      return this.$route.meta.layout || 'default-layout'
    }
  }
}
</script>

<style>
@import url('./styles/dist/FontTypes.css');
@import url('./styles/ColorStyle.css');
@import url('./styles/DefaultStyle.css');
@import url('./styles/ModalStyle.css');

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-active {
  opacity: 0;
}
</style>
