<template>
  <b-alert
    :show="dismissCountDown"
    fade
    dismissible
    :variant="variant"
    @dismissed="closeAlert"
    @dismiss-count-down="countDownChanged"
    :class="['alert', `bg-${variant}-alert`]"
  >
    <slot></slot>
  </b-alert>
</template>

<script>
export default {
  name: 'SAlert',
  props: {
    showAlert: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'success'
    }
  },
  data: () => ({
    dismissCountDown: 0
  }),
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown

      if (dismissCountDown < 1) {
        this.closeAlert()
      }
    },
    closeAlert () {
      this.$emit('closeAlert')
    }
  },
  watch: {
    showAlert (newState) {
      if (newState === true) {
        this.dismissCountDown = 5
      } else if (newState === false) {
        this.dismissCountDown = 0
      }
    }
  }
}
</script>

<style scoped>
.alert {
  margin: auto;
  text-align: center;
  position: absolute;
  z-index: 50;
  top: 18px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
}

.alert-success {
  background: var(--color-ui-element-grass-100);
}

.alert-secondary {
  background: var(--color-ui-element-neutral-light-4);
}

@media screen and (min-width: 531px) {
  .alert {
    top: 59px;
    width: auto;
  }
}
</style>
