<template>
  <div class="default-layout">
    <navbar />
    <slot></slot>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
export default {
  name: 'DefaultLayout',
  components: {
    Navbar
  }
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .navbar {
    border-bottom: 1px solid var(--color-ui-element-neutral-light-3);
  }
}

</style>
