import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Masuk',
      metaTags: [
        {
          name: 'description',
          content: 'Daftar melalui satu akun untuk Sekolahmu, Kariermu, Rencanamu, dan Talentics'
        }
      ],
      layout: 'auth-layout'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Authentication/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: 'Daftar/Register',
      metaTags: [
        {
          name: 'description',
          content: 'Daftar melalui satu akun untuk Sekolahmu, Kariermu, Rencanamu, dan Talentics'
        }
      ],
      layout: 'auth-layout'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Authentication/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      title: 'Atur Ulang Kata Sandimu',
      metaTags: [
        {
          name: 'description',
          content: 'Daftar melalui satu akun untuk Sekolahmu, Kariermu, Rencanamu, dan Talentics'
        }
      ],
      layout: 'default-layout'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Authentication/ResetPassword.vue'),
    beforeEnter: (to, from, next) => {
      if (!(to.query.verification && to.query.code)) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const hasSourceQueryParam = (route) => {
  return !!route.query.source
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} | ${process.env.VUE_APP_PLATFORM_NAME}`
  } else {
    document.title = `${process.env.VUE_APP_PLATFORM_NAME}`
  }

  if (!hasSourceQueryParam(to) && hasSourceQueryParam(from)) {
    next({ ...to, query: from.query })
  } else {
    next()
  }
})

export default router
