export default {
  namespaced: true,
  state: {
    browserName: '',
    operationSystemName: '',
    latitude: '',
    longitude: '',
    formattedSource: ''
  },
  mutations: {
    CHANGE_BROWSER_NAME (state, payload) {
      state.browserName = payload
    },
    CHANGE_OPERATION_SYSTEM_NAME (state, payload) {
      state.operationSystemName = payload
    },
    CHANGE_LATITUDE (state, payload) {
      state.latitude = payload
    },
    CHANGE_LONGITUDE (state, payload) {
      state.longitude = payload
    },
    CHANGE_FORMATTED_SOURCE (state, payload) {
      state.formattedSource = payload
    }
  },
  actions: {
  }
}
