<template>
  <div class="auth-layout">
    <navbar />

    <s-alert
      variant="success"
      :showAlert="isResetPasswordSuccessAlertShow"
      @closeAlert="closeResetPasswordSuccessAlert()"
      >
      Kata sandimu berhasil diubah. Silakan masuk kembali
    </s-alert>

    <div class="main-container container">
      <div class="row">
        <div class="col-md-7 d-none d-md-block">
          <section class="hero">
            <div class="hero-img-container">
              <transition name="component-fade" mode="out-in">
                <img
                  id="hero-img"
                  class="hero-img"
                  :src="heroImageUrl"
                  :key="this.$route.name"
                  alt="Sekolah.mu" />
              </transition>
            </div>
          </section>
        </div>
        <div class="col-md-5">
          <slot></slot>
          <mini-footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import MiniFooter from '@/components/MiniFooter.vue'
import SourcePlatformMixin from '@/mixins/sourcePlatformMixin'
import UserAgentMixin from '@/mixins/userAgentMixin'
import SAlert from '@/components/atoms/alerts/SAlert'

export default {
  name: 'AuthLayout',
  mixins: [SourcePlatformMixin, UserAgentMixin],
  components: {
    Navbar,
    MiniFooter,
    SAlert
  },
  data: () => ({
    isResetPasswordSuccessAlertShow: false
  }),
  computed: {
    heroImageUrl () {
      if (this.$route.name === 'Register') {
        return 'https://cdn.sekolah.mu/assets/sso/register.png'
      }

      return 'https://cdn.sekolah.mu/assets/sso/login.png'
    }
  },
  methods: {
    closeResetPasswordSuccessAlert () {
      this.isResetPasswordSuccessAlertShow = false
    }
  },
  created () {
    this.getSourcePlatform()
    this.storeUserAgent()

    this.isResetPasswordSuccessAlertShow = this.$store.state.isResetPasswordSuccess
  }
}
</script>

<style scoped>
.auth-layout {
  background-image: none;
  min-height: 100vh;
}
@media screen and (min-width: 768px) {
  .auth-layout {
    background-image: url('https://cdn.sekolah.mu/assets/sso/background-top.png'), url('https://cdn.sekolah.mu/assets/sso/background-bottom.png');
    background-size: contain;
    background-position: top, bottom;
    background-repeat: no-repeat;
  }
}

.main-container {
  margin-top: 32px;
}
@media screen and (min-width: 768px) {
  .main-container {
    margin-top: 35px;
  }
}

section.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.hero-img-container {
  width: 570px;
  height: 570px;
}
.hero-img {
  width: 100%;
  object-fit: cover;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-active {
  opacity: 0;
}

.mini-footer {
  margin-top: 63px;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .mini-footer {
    margin-top: 44px;
    margin-bottom: 102px;
  }
}
</style>
