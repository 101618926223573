const sourcePlatformMixin = {
  methods: {
    getSourcePlatform () {
      const urlParams = new URLSearchParams(window.location.search)
      const sourceParam = urlParams.get('source')

      let source = null

      if (['rencanamu', 'talentics', 'kariermu-jobs'].includes(sourceParam)) {
        source = sourceParam
      }

      this.storeSourcePlatform(source)
    },
    storeSourcePlatform (source) {
      this.$store.commit('CHANGE_SOURCE_PLATFORM', source)
    },
    getSourcePlatformRedirectUrl (token) {
      const sourcePlatform = this.$store.state.sourcePlatform
      let sourcePlatformBaseUrl = null

      if (sourcePlatform === 'rencanamu') {
        sourcePlatformBaseUrl = process.env.VUE_APP_RENCANAMU_URL
      } else if (sourcePlatform === 'talentics') {
        sourcePlatformBaseUrl = process.env.VUE_APP_TALENTICS_JOBS_URL
      } else if (sourcePlatform === 'kariermu-jobs') {
        sourcePlatformBaseUrl = process.env.VUE_APP_KARIERMU_JOBS_URL
      }

      const redirectUrl = new URL(sourcePlatformBaseUrl + '/oauth/sekolahmu')
      redirectUrl.searchParams.append('token', token)

      return redirectUrl
    },
    redirectToSourcePlatform (token) {
      const redirectUrl = this.getSourcePlatformRedirectUrl(token)
      window.location = redirectUrl
    }
  }
}

export default sourcePlatformMixin
