import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins
import '@/plugins/fontAwesome'
import '@/plugins/bootstrapVue'

// Layouts
import '@/layouts'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import XSSFilter from '@/functions/XSSFilter'
import linkedinOauth from '@/functions/linkedinOauth'

Vue.config.productionTip = false

// Declare global function
Vue.prototype.$XSSFilter = XSSFilter
Vue.prototype.$linkedinOauth = linkedinOauth

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
