import Vue from 'vue'
import Vuex from 'vuex'

import userAgent from '@/store/modules/userAgent'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sourcePlatform: null,

    isRegisterOauthFormOpen: false,
    oauthUserInformation: {},

    isResetPasswordSuccess: false
  },
  mutations: {
    CHANGE_SOURCE_PLATFORM (state, payload) {
      state.sourcePlatform = payload
    },
    CHANGE_IS_REGISTER_OAUTH_FORM_OPEN (state, payload) {
      state.isRegisterOauthFormOpen = payload
    },
    CHANGE_OAUTH_USER_INFORMATION (state, payload) {
      state.oauthUserInformation = payload
    },
    CHANGE_IS_RESET_PASSWORD_SUCCESS (state, payload) {
      state.isResetPasswordSuccess = payload
    }
  },
  actions: {
  },
  modules: {
    userAgent
  }
})
