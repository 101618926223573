const clientId = process.env.VUE_APP_LINKEDIN_OAUTH_CLIENT_ID
const platformUrl = process.env.VUE_APP_PLATFORM_URL
const redirectUrl = platformUrl + '/oauth/linkedin'

const windowOptions = [
  'width=600',
  'height=600',
  'resizable=n',
  `top=${window.top.outerHeight / 2 + window.top.screenY - (600 / 2)}`,
  `left=${window.top.outerWidth / 2 + window.top.screenX - (600 / 2)}`
]

const openWindow = () => {
  const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=foobar&scope=r_liteprofile%20r_emailaddress`
  const popup = window.open(url, 'popup', windowOptions.toString())

  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      try {
        var params = new URLSearchParams(popup.location.search)
        var code = params.get('code')

        if (code || popup.closed) {
          clearInterval(interval)
          popup.close()

          return resolve(code)
        }
      } catch (e) {
      }
    }, 100)
  })
}

const getAuthCode = () => {
  return openWindow()
}

export default { getAuthCode }
