import deviceDetector from 'mobile-device-detect'

const userAgentMixin = {
  methods: {
    getBrowserName () {
      const browserName = deviceDetector.browserName
      this.$store.commit('userAgent/CHANGE_BROWSER_NAME', browserName)
    },
    getOperationSystemName () {
      const operationSystemName = deviceDetector.osName
      this.$store.commit('userAgent/CHANGE_OPERATION_SYSTEM_NAME', operationSystemName)
    },
    getFormattedSource () {
      let source = this.$store.state.sourcePlatform

      if (this.$store.state.sourcePlatform === 'kariermu-jobs') {
        source = 'talentics'
      }

      const formattedSource = source + '-' + (!deviceDetector.isMobile ? 'web' : 'webview')
      this.$store.commit('userAgent/CHANGE_FORMATTED_SOURCE', formattedSource)
    },
    storeUserAgent () {
      this.getBrowserName()
      this.getOperationSystemName()
      this.getFormattedSource()
    }
  }
}

export default userAgentMixin
