<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <div class="navbar-brand">
        <template v-if="source == 'rencanamu'">
          <img id="navbar-brand-logo" src="https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg" alt="Sekolahmu" />
          <img id="navbar-brand-logo" src="https://rencanamu.id/assets/frontend/img/rencanamu-logo-red.png" alt="Rencanamu" />
        </template>
        <template v-else-if="source == 'talentics'">
          <img id="navbar-brand-logo" src="https://app.talentics-tech.com/images/talentics-logo-purple-new.png" alt="Talentics" />
        </template>
        <template v-else-if="source == 'kariermu-jobs'">
          <img id="navbar-brand-logo" src="https://cdn.sekolah.mu/assets/logo/kariermu-plain-logo.svg" alt="Kariermu" />
        </template>
        <template v-else>
          <img id="navbar-brand-logo" src="https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg" alt="Sekolahmu" />
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  computed: {
    source () {
      return this.$store.state.sourcePlatform
    }
  }
}
</script>

<style scoped>
.navbar {
  background-color: transparent;
  padding: 16px;
  margin-top: 40px;
}
.navbar-brand {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-right: 0;
}
.navbar-brand img {
  width: 140px;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .navbar {
    margin-top: auto;
  }
  .navbar-brand {
    display: block;
    width: auto;
  }
  .navbar-brand img {
    width: 170px;
  }
}
</style>
